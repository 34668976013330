import api from "./api";
const prefix = "template";

export default {
  getAllTemplates: function () {
    return api.get(`${prefix}`);
  },
  getAllTemplatesForMenu: function (keycloakId) {
    return api.get(`${prefix}/menu/${keycloakId}`);
  },
  getAllReadyTemplates: function () {
    return api.get(`${prefix}/ready-templates`);
  },
  getPermissionSections: function (organizationId) {
    return api.get(`${prefix}/sections/find-by/permission/${organizationId}`);
  },
  getSectionsByTemplate: function (templateId) {
    return api.get(`${prefix}/${templateId}/section`);
  },
  getSectionHierarchy: function (templateId) {
    return api.get(`${prefix}/section-hierarchy/${templateId}`);
  },
  getTemplate: function (id) {
    return api.get(`${prefix}/${id}`);
  },
  createTemplate: function (templateData) {
    return api.post(`${prefix}`, templateData);
  },
  deleteTemplate: function (id) {
    return api.delete(`${prefix}/${id}`);
  },
  updateTemplate: function (id, templateData) {
    return api.put(`${prefix}/${id}`, templateData);
  },
  getSection: function (id) {
    return api.get(`${prefix}/section/${id}`);
  },
  updateSectionHierarchy: function (templateId, hierarchyData) {
    return api.put(`${prefix}/section-hierarchy/${templateId}`, hierarchyData);
  },
  getSections: function (templateId) {
    return api.get(`${prefix}/${templateId}/sections`);
  },
  getQuestion: function (id) {
    return api.get(`${prefix}/question/${id}`);
  },
  cloneQuestion: function (questionId) {
    return api.post(`${prefix}/clone-question/${questionId}`);
  },
  getQuestionsBySubject: function (subjectId) {
    return api.get(`${prefix}/question/find-by/subject/${subjectId}`);
  },
  getQuestionsBySection: function (sectionId) {
    return api.get(`${prefix}/question/find-by/section/${sectionId}`);
  },
  getQuestionsToPrecondition: function (sectionId) {
    return api.get(`${prefix}/question-precondition/find-by/section/${sectionId}`);
  },
  createSection: function (sectionData) {
    return api.post(`${prefix}/section`, sectionData);
  },
  uploadSectionAvatar: function (sectionId, file) {
    return api.post(`${prefix}/section-avatar`, file, {
      params: { sectionId },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteSection: function (id) {
    return api.delete(`${prefix}/section/${id}`);
  },
  updateSection: function (id, sectionData) {
    return api.put(`${prefix}/section/${id}`, sectionData);
  },
  updateQuestion: function (id, questionData) {
    return api.put(`${prefix}/question/${id}`, questionData);
  },
  createQuestion: function (questionData) {
    return api.post(`${prefix}/question`, questionData);
  },
  deleteQuestion: function (id) {
    return api.delete(`${prefix}/question/${id}`);
  },
  deleteOptionQuestion: function (id) {
    return api.delete(`${prefix}/option/${id}`);
  },
  updateReorderQuestions: function (reordenedData) {
    return api.post(`${prefix}/reorder-questions`, reordenedData);
  },
  getUsedKeysQuestion: function (sectionId) {
    return api.get(`${prefix}/question/used-keys/${sectionId}`);
  },
  getUsedKeysSection: function (templateId) {
    return api.get(`${prefix}/section/used-keys/${templateId}`);
  },
  uploadTemplate: function (templateId, file) {
    return api.post(`${prefix}/upload/${templateId}`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  downloadTemplate: function (templateId) {
    return api.get(`${prefix}/download/${templateId}`, { responseType: "blob" });
  },
  importTemplate: function (jsonData) {
    return api.post(`${prefix}/import`, jsonData, {
      headers: { "Content-Type": "application/json" },
    });
  },
  exportTemplate: function (templateId) {
    return api.get(`${prefix}/export/${templateId}`);
  },
}